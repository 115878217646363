/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import $ from 'jquery'

import GridLayout, { GridItem } from './utils/GridLayout'

import Header from "./header"
import style from './style/header.module.scss'

import logo from '../images/utils/logo-footer.svg'
import sustainable from '../images/utils/sustainable-development-goals.svg'
import goals from '../images/utils/goals.svg'
import onePercent from '../images/utils/one-percent.svg'

import E_PRINT_02 from '../images/utils/footer/E_PRINT_02.png';
import E_WEB_03 from '../images/utils/footer/E_WEB_03.png';
import E_WEB_12 from '../images/utils/footer/E_WEB_12.png';
import E_WEB_13 from '../images/utils/footer/E_WEB_13.png';
import E_WEB_14 from '../images/utils/footer/E_WEB_14.png';

/**
 * Newsletter error
 * @param {[type]} options.msg error msg
 */
function Error({msg}) {
  return <div className = { style.NewsletterError }>{msg}</div>
}

/**
 * Newsletter
 */
function Newsletter() {
  const [ email, setEmail ] = useState('');
  const [ error, setError ] = useState(null);
  const mailchimp = 'https://algamafoods.us4.list-manage.com/subscribe/post-json?u=bda695f9ffe18d6ae304fd424&id=3c3bf01294&c=?';

  /** Handle email update */
  function onChange({setEmail}) {
    return (evt) => { setEmail(evt.target.value); }
  }

  /** Handle form submit */
  function onSubmit({email,setEmail}) {
    return (evt) => {
      evt.preventDefault();
      $.ajax({
        type: 'get',
        url: mailchimp,
        data: { EMAIL: email },
        cache: false,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        error: console.error,
        success: ({result, msg}) => {
          if (result !== 'error' || msg.indexOf(email) == 0 ) {
            setEmail('Done !');
            setError(null);
          } else {
            setError('Invalid email address.');
          }
        }
      })
    }
  } 

  return(
    <form 
      onSubmit = { onSubmit({email, setEmail}) }
      className = { style.Newsletter }>
      <input 
        onChange = { (evt) => { setEmail(evt.target.value); } }
        type = 'email'
        name = 'email'
        value = { email }
        onChange = { onChange({setEmail}) }
        placeholder = '(Enter your email address)'
        className = { style.Newsletter__input} />
      <button 
        type = 'submit'
        className = { style.Newsletter__submit }>
        Submit
      </button>
      { error && <Error msg = { error }/> }
    </form>
  )
}

/**
 * Footer navigation
 * @type {Array}
 */
const footerLinks = [
  [{
    name: 'Company',
    link: '/company',
  },{
    name: 'Services',
    link: '/services',
  },{
    name: 'Vision',
    link: '/manifesto',
  }],[{
    name: 'Career',
    link: 'https://www.welcometothejungle.com/en/companies/algama/jobs',
    extern: true,
  },{
    name: 'Press kit',
    link: '/press-kit'
  },{
    name: 'Q&A',
    link: '/Q-A'
  },],[{
    name: 'Contact us',
    link: '/contact'
  }]
];

const legalLinks = [
  [{
    link: '/terms',
    name: 'Terms of Use'
  }, {
    link: '/privacy',
    name: 'Privacy Policy'
  }],
  [{
    name: '© COPYRIGHT 2020 ALGAMA'
  }, {
    link: 'https://www.callmelord.com/',
    name: 'DESIGN BY LORD',
    extern: true
  }]
]

/**
 * Social icons
 */
const socialIcons = [
  {className: 'facebook', href: 'https://www.facebook.com/algamafoods/' },
  {className: 'twitter', href: 'https://twitter.com/algamafoods' },
  {className: 'linkedin', href: 'https://www.linkedin.com/company/algama/' },
  {className: 'instagram', href: 'https://www.instagram.com/algamafoods/' },
  {className: 'medium', href: 'https://medium.com/@Algamafoods' },
  {className: 'youtube', href: 'https://www.youtube.com/channel/UCchhsRckqTprIuGRWaQPHiQ' },
]

function displayLink({ name, link, extern }, i) {
  if (!link) {
    return (
      <li key = { name }>
        <span>{ name }</span>
      </li>
    )
  }
  if ( extern ){
    return (
      <li key = { name }>
        <a 
          href = { link }
          target = '_blank'
          rel="noopener noreferrer">{ name }</a>
      </li>
    )
  }
  return (
    <li key = { name }>
      <Link to = { link }>{ name }</Link>
    </li>
  )
}

const Layout = ({ children, pageName }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div className = { pageName }>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <footer className = { style.Footer }>

          {/* Logo */}
          <div className = 'wrapper'>

            <Link
              className = { style.footerLogo }
              to = '/'>
              <img src = { logo } alt = 'Algama' />
            </Link>

          </div>

          <div className = { style.topFooter }>

            <GridLayout>
              <GridItem grid = {{
                col: { md: 6, lg: 6 }
              }}>

                {/* Navigation */}
                <ul className = { style.footerNav }>
                  { footerLinks.map((column, i) => (
                    <li key = { i } >
                      <ul>
                        {column.map(displayLink)}
                      </ul>
                    </li>
                  )) }
                </ul>

                {/* Brands */}
                <div className = { style.Brands }>
                  <h6 className = 'title title--footer'>Our brands</h6>
                  <a 
                    target = '_blank'
                    rel="noopener noreferrer"
                    href = 'https://www.thegoodspoon.co'>
                    www.thegoodspoon.co
                  </a>
                </div>

                {/* Social icons */}
                <div className = { style.socialIcons }>
                  <h6 className = 'title title--footer'>Follow us</h6>
                  <ul>{ socialIcons.map(({className, href}) => (
                    <li key = { className }>
                      <a 
                        href = { href }
                        className = { style[className] }
                        rel="noopener noreferrer"
                        target = '_blank'/>
                    </li>
                  ))}</ul>
                </div>

              </GridItem>
              <GridItem grid = {{
                push: { md: 1, lg: 1 },
                col: { md: 5, lg: 5 }
              }}>
                {/* Newsletter */}
                <h6 className = 'title title--footer'>Join our mailing list</h6>
                <Newsletter />

                {/* Associations */}
                <div className = {style.Associations }>
                  <div>
                    <h6 className = 'title title--footer'>
                      un’s Sustainable development goals
                    </h6>
                    <p>
                      Algama’s project meets objectives of the UN’s sustainable development goals
                    </p>
                    <div className = { style.Goals} >
                      <a
                        target = '_blank' 
                        rel="noopener noreferrer"
                        href = 'https://www.un.org/sustainabledevelopment/sustainable-development-goals/'>
                        <img 
                          className = { style.logoSustainable }
                          src = { sustainable } 
                          alt = 'sustainable development goals' />
                      </a>
                      <a 
                        href = 'https://www.un.org/sustainabledevelopment/hunger/'
                        target = '_blank'>
                        <img 
                          src = { E_PRINT_02 }
                          alt = 'zero hunger'
                          className = { style.logoGoals } />
                      </a>
                      <a 
                        href = 'https://www.un.org/sustainabledevelopment/health/'
                        target = '_blank'>
                        <img 
                          src = { E_WEB_03 }
                          alt = 'good health and well-being'
                          className = { style.logoGoals } />
                      </a>
                      <a 
                        href = 'https://www.un.org/sustainabledevelopment/sustainable-consumption-production/'
                        target = '_blank'>
                        <img 
                          src = { E_WEB_12 }
                          alt = 'responsible consumption and production'
                          className = { style.logoGoals } />
                      </a>
                      <a 
                        href = 'https://www.un.org/sustainabledevelopment/climate-change/'
                        target = '_blank'>
                        <img 
                          src = { E_WEB_13 }
                          alt = 'climate action'
                          className = { style.logoGoals } />
                      </a>
                      <a 
                        href = 'https://www.un.org/sustainabledevelopment/oceans/'
                        target = '_blank'>
                        <img 
                          src = { E_WEB_14 }
                          alt = 'life below water'
                          className = { style.logoGoals } />
                      </a>
                    </div>
                  </div>


                  <div>
                    <h6 className = 'title title--footer'>
                      Algama is a 1% for the planet member
                    </h6>
                    <a
                      href = 'https://www.onepercentfortheplanet.org'
                      rel="noopener noreferrer"
                      target = '_blank'>
                      <img 
                        className = { style.logoOne }
                        src = { onePercent }
                        alt = '1% for the planet' />
                    </a>
                  </div>
                </div>

              </GridItem>
            </GridLayout>

          </div>

          <div className = 'wrapper'>
            <div className = { style.legalFooter }>
              { legalLinks.map((column, i) => (
                <ul key = { i } >{column.map(displayLink)}</ul>
              )) }
            </div>
          </div>

        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
