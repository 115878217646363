import React from 'react'

import { Watch } from 'scrollmonitor-react'
import Lottie from 'react-lottie'

export default Watch(({ isInViewport, id, className, animationData, height, width }) => {	
	return (
		<div 
			id = { id } 
			className = { className }>
			<Lottie
				height = { height }
				width = { width }
				isPaused = { !isInViewport }
				options = {{
					loop: true,
					autoplay: true, 
					animationData: animationData,
					rendererSettings: {
						preserveAspectRatio: 'xMidYMid slice'
					}}
				} />
		</div>
	)
});
